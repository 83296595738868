import * as React from 'react';
import { graphql } from 'gatsby';
import { useFlexSearch } from 'react-use-flexsearch';
import { useLocation } from '@reach/router';

import { TContentfulSection } from '../utils/types';

// Components
import HeadMeta from '../components/Molecules/HeadMeta/HeadMeta';
import Section from 'fatcat-ui-library/components/Molecules/Section';
import SearchProductInput from '../components/Molecules/SearchProductInput/SearchProductInput';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Text } from 'fatcat-ui-library/components/Atoms/Text';
import SearchProductsList from '../components/Sections/Products/SearchProductsList';

const Search: React.FC<{ data: { localSearchProducts: any, promoBannerSection: TContentfulSection} }> = ({
	data: {
		localSearchProducts,
	},
}) => {
	const { index, store } = localSearchProducts;
	const { search } = useLocation();
	const urlSearchParams = new URLSearchParams(search);
	const searchParam = urlSearchParams.get('keyword') || '';
	const results = useFlexSearch(searchParam, index, store);

	return (
		<main>
			<Section
				padding={['t80', 'b80']}
			>
				<Flex direction="column" gap="24px">
					<SearchProductInput />
					<Text textAlign="center" textColor="secondary" fontWeight="medium">
						Results
						<Text fontWeight="bold">{' · '}</Text>
						{results.length || 0}
					</Text>
					<Flex
						marginTop="s56"
						tablet={[{ marginTop: 's24' }]}
					>
						<SearchProductsList results={results} />
					</Flex>
				</Flex>
			</Section>
		</main>
	);
};

export default Search;

export const SearchPageQuery = graphql`
  query {
    localSearchProducts {
      index
      store
    }
  }
`;

export const Head: React.FC<{ data: { localSearchProducts: any } }> = ({
	data: {
		localSearchProducts,
	},
}) => {
	const { index, store } = localSearchProducts;
	const { search } = useLocation();
	const urlSearchParams = new URLSearchParams(search);
	const searchParam = urlSearchParams.get('keyword') || '';
	const results = useFlexSearch(searchParam, index, store);
	return (
		<HeadMeta
			meta={{
				pageTitle: `(${results.length || 0}) Enjoy good health | Search page`,
				metaDescription: '',
			}}
		/>
	);
};

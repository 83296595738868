import React, { useMemo } from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';

// helpers
import { chunk } from '../../../utils/helpers';
import { useGetAvailableQuantity } from 'src/utils/hooks/useGetAvailableQuantity';

// Atoms
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Paragraph } from 'fatcat-ui-library/components/Atoms/Paragraph';

// Molecules
import ProductCard from '../../Molecules/Cards/ProductCardV2';

// Organisms
import Pagination from '../../Organisms/Pagination/Pagination';

// Types
import { type TProduct } from 'src/utils/types';

// Assets
import NoResult from 'src/assets/images/svg-inline/search-no-result.svg';

type TSearchProductsList = {
	results: TProduct[]
}

const SearchProductsList: React.FC<TSearchProductsList> = (props) => {
	const { results } = props;
	const { search, pathname } = useLocation();
	const { getAllProductsOutOfStockLast, loading } = useGetAvailableQuantity();
	const allProducts = useMemo(() => loading ? [] : getAllProductsOutOfStockLast(), [getAllProductsOutOfStockLast, loading]);
	const productsPerPage = 6;

	const urlSearchParams = new URLSearchParams(search);

	// remove url param **page** if typed directly in search bar and value is one
	if (urlSearchParams.get('page') === '1') {
		urlSearchParams.delete('page');
		const newSearch = urlSearchParams.toString();
		navigate(newSearch ? `?${newSearch}` : pathname);
	}
	// get filterd query params
	const currentPage = parseInt(urlSearchParams.get('page') || '1', 10);

	// calculate number of pages
	const renderingProducts = allProducts.reduce((prev: TProduct[], curr: TProduct) => {
		if (results.some(item => item.slug === curr.slug)) {
			prev.push(curr);
		}
		return prev;
	}, []);
	const numberOfProductsPages = Math.ceil((renderingProducts.length) / productsPerPage);
	return (
		<Flex
			direction="column"
			alignItems="center"
		>
			{results.length ? (

				<Flex
					wrap
					maxW="805px"
					gap="54px 20px"
					largeTablet={[{ maxW: '530px' }]}
					tablet={['justifyContentCenter', { gap: '30px 20px' }]}
				>
					{chunk(renderingProducts, productsPerPage)
						.map((grp, index) => index + 1 === currentPage && grp.map((item, index) => (
							<ProductCard key={item.id} {...item} haveAddButton inSwiper={false} />
						)))}
				</Flex>
			) : (
				<Flex
					direction="column"
					maxW="568px"
					gap="16px"
					alignItems="center"
				>
					<NoResult />
					<Paragraph textAlign="center">{'It seems like we couldn\'t find the product you were looking for.'}</Paragraph>
					<Paragraph
						marginTop="s8"
						size="small"
						textAlign="center"
						textColor="secondary"
						tablet={[{ marginTop: 's0' }]}
					>
						{'Don\'t worry though, you can also search by health concern, vitamin or supplement to find what you need.'}
					</Paragraph>
				</Flex>
			)}
			<Pagination
				currentPage={currentPage}
				numPages={numberOfProductsPages}
			/>
		</Flex>
	);
};

export default SearchProductsList;
